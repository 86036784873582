import "../../styles/gridder.scss"

import { Gridder } from './gridder.js'

window.addEventListener('DOMContentLoaded', (event) => {
    const element = document.querySelectorAll("[fr-gridder]")
    element.forEach(item => new Gridder(item))

    
});

