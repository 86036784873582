export class Gridder {
    constructor(el) {
        // .menu element
        this.DOM = { el: el };
        // the menu items
        this.setGrids();
    }
    setGrids() {
        const attrs = this.DOM.el.attributes;
        const itemAttributes = [];
        const itemsAttributes = [];
        const settings = {};

        for (const key in attrs) {
            const element = attrs[key];
            if (typeof element === 'object') {
                // Handle grids
                if (element.name.includes('fr-grid-item-')) {
                    const name = parseInt(element.name.split('-').slice(-1));
                    itemAttributes.push({
                        item: name,
                        layout: JSON.parse(element.value)
                    });
                }
                if (element.name.includes('fr-grid-items')) {
                    const nth = element.name.split('-').slice(-1)[0];
                    itemsAttributes.push({
                        nth,
                        layout: JSON.parse(element.value)
                    });
                }
                if (element.name.startsWith('fr-gridder-min-w')) {
                    settings['min-w'] = element.value;
                }
            }
        }
        const gridElements = this.DOM.el.children;
        itemAttributes.forEach((i) => {
            gridElements
                .item(i.item - 1)
                .classList.add('fr-col-span-' + i.layout[0]);
            gridElements
                .item(i.item - 1)
                .classList.add('fr-row-span-' + i.layout[1]);
        });

        const getNthItems = (arr, nth) =>
            arr.filter((e, i) => i % nth === nth - 1);

        const getOddItems = (arr) => arr.filter((e, i) => i % 2);

        const getEvenItems = (arr) => arr.filter((e, i) => (i % 2) - 1);

        itemsAttributes.forEach((i) => {
            const nth = i.nth;
            const colClass = i.layout[0];
            const rowClass = i.layout[1];
            let itemsToSet = [];
            if (nth === 'odd') {
                itemsToSet = getOddItems(Array.from(gridElements));
            } else if (nth === 'even') {
                itemsToSet = getEvenItems(Array.from(gridElements));
            } else {
                itemsToSet = getNthItems(Array.from(gridElements), nth);
            }

            itemsToSet.forEach((item) => {
                item.classList.add('fr-col-span-' + colClass);
                item.classList.add('fr-row-span-' + rowClass);
            });
        });

        const media_query = window.matchMedia(
            `( min-width: ${settings['min-w']})`
        );
        const toggle_mobile = (e) => {
            if (e.matches) {
                this.DOM.el.classList.add('fr-gridder-active');
            } else {
                this.DOM.el.classList.remove('fr-gridder-active');
            }
        };
        toggle_mobile(media_query);
        media_query.addEventListener('change', toggle_mobile);
    }
}
